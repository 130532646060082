import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    TableContainer, Tooltip,
    TableBody, Paper, Table, TableHead, TableRow,
    TableCell,
    IconButton, Typography, TablePagination, TextField, Grid, Button
} from '@material-ui/core';
import api from "../../../../services/api";
import Swal from "sweetalert2";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FindInPage from "@material-ui/icons/SearchSharp";
import PrintIcon from '@material-ui/icons/Print';
import {getErrorMessage} from "../../../../helpers/error";
import {getCurrentDate} from "../../../../utils/dateUtils";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";

const useStyles = makeStyles(() => ({
    root: {},
    allow: {
        width: '90.0px',
        backgroundColor: '#5DE2A5',
        //display: 'inline-block',
        color: '#393A68',
        textAlign: 'center',
        height: '70px',
        boxSizing: 'border-box',
        border: '1px solid #F2F2F2',
        minWidth: '80px',
        padding: '12px',
        fontWeight: 'bold',
        fontSize: '14px',
    },
    notAllow: {
        width: '90.0px',
        backgroundColor: '#F14D76',
        //display: 'inline-block',
        color: '#393A68',
        textAlign: 'center',
        height: '70px',
        boxSizing: 'border-box',
        border: '1px solid #F2F2F2',
        minWidth: '80px',
        padding: '12px',
        fontWeight: 'bold',
        fontSize: '14px'
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: 5,
        flex: 1,
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const ReportWaste = props => {
    const { className, history, ...rest } = props;
    const [foodWaste, setFoodWaste] = useState([]);
    const [mealsAll, setMealsALl] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [dateSearch, setDateSearch] = React.useState('2020-05-20');
    const [dateEndSearch, setDateEndSearch] = React.useState('2020-05-20');
    const level_user = localStorage.getItem("@rucedro-acess-level-user");

    const classes = useStyles();

    //configuration alert
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    function loadAlert(icon, message) {
        Toast.fire({
            icon: icon,
            title: message
        });
    }

    async function loadFoodWast(page){
        try {
            // Construa a URL com base nos parâmetros fornecidos
            let url = `food-waste?start_date=${dateSearch}&end_date=${dateEndSearch}`;
            //let url = 'report/list-scheduling';

            const response = await api.get(url);
            if (response.status === 200) {
                if(response.data.message){
                    loadAlert('error', response.data.message);
                }
                setFoodWaste([]);
                setFoodWaste(response.data);
                //setTotal(response.total);

            }
        } catch (error) {
            loadAlert('error', getErrorMessage (error));
        }
    }

    const handlePageChange = (event, page) => {
        loadFoodWast(page+1)
        setPage(page);
    };

    const handleRowsPerPageChange = event => {
        setRowsPerPage(event.target.value);
    };


    useEffect(() => {
        loadFoodWast();

        const dateNow = getCurrentDate();

        setDateSearch(dateNow);
        setDateEndSearch(dateNow);



    }, []);

    const handleBack = () => {
        history.goBack();
    };

    const handleDateChange = (event) => {
        setDateSearch(event.target.value);
    };

    const handleDateEndChange = (event) => {
        setDateEndSearch(event.target.value);
    };

    const onClickSearch = (event) => {
        loadFoodWast(page);
    };

    const onClickHandleNewFoodWaste = () => {
        history.push('/food-waste-details');
    }

    const onClickEdit = (id) => {
        history.push('/food-waste-details/'+id);
    }

    return (
        <div>
            <div className={classes.contentHeader}>
            
                <IconButton onClick={handleBack}>
                    <ArrowBackIcon />
                </IconButton>
            <div className={classes.row}>
                <Typography variant="h5" className={classes.title}>{'Desperdício de comida'}</Typography>
                {level_user === "RECEPCAO" && <Button variant="contained" color="primary" className={classes.exportButton}
                        onClick={onClickHandleNewFoodWaste}>Novo Desperdício</Button> }
            </div>

            </div>
            <br/>
            <Grid container spacing={3} style={{ marginBottom: '10px' }}>
                <Grid item container spacing={3}>
                    <Grid item md={3} xs={10}>
                        <TextField
                            key="dateSearch"
                            fullWidth
                            type="date"
                            label="Data Inicial"
                            margin="dense"
                            name="dateSearch"
                            onChange={handleDateChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={dateSearch}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={3} xs={10}>
                        <TextField
                            key="dateEndSearch"
                            fullWidth
                            type="date"
                            label="Data Final"
                            margin="dense"
                            name="dateEndSearch"
                            onChange={handleDateEndChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={dateEndSearch}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={1} xs={10}>
                        <Tooltip title="Pesquisar">
                            <Button onClick={onClickSearch}>
                                <FindInPage fontSize="large" />
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>

            <TableContainer component={Paper}>
                {/*<TablePagination
                    component="div"
                    count={total}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[20]}
                />*/}
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{fontWeight: 'bold'}}>Data</TableCell>
                            <TableCell align="center" style={{fontWeight: 'bold'}}>Menu</TableCell>
                            <TableCell align="center" style={{fontWeight: 'bold'}}>Nº de Estudantes</TableCell>
                            <TableCell align="center" style={{fontWeight: 'bold'}}>Total Distribuido (kg)</TableCell>
                            <TableCell align="center" style={{fontWeight: 'bold'}}>Rejeito Por Pessoa (g)</TableCell>
                            <TableCell align="center" style={{fontWeight: 'bold'}}>Rejeito Total (kg)</TableCell>
                            <TableCell align="center" style={{fontWeight: 'bold'}}>Resto Ingestão (%)</TableCell>
                            <TableCell align="center" style={{fontWeight: 'bold'}}>Classificação</TableCell>
                            <TableCell align="center" style={{fontWeight: 'bold'}}>Pessoas que poderiam ser alimentadas</TableCell>
                            <TableCell align="center" style={{fontWeight: 'bold'}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {foodWaste ? foodWaste.map((result) => (
                            <TableRow key={result.id}>
                                <TableCell align="center" component="th" scope="row">
                                    {result.waste_date.split('-').reverse().join('/')} - {result.menu.meal.description}
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    {result.menu.description }
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    { result.total_menu}
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    { result.total_distributed} kg
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    {result.reject_per_person} g
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    {result.total_food_waste} kg
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    {result.ingestion_percentage} %
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    {result.classification}
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    {result.people_fed}
                                </TableCell>
                                {level_user === "RECEPCAO" && <TableCell className={classes.row}>
                                    <Tooltip title="Editar">
                                        <Button
                                            className={classes.buttonEdit}
                                            onClick={() => onClickEdit(result.id)}>
                                            <Edit fontSize="medium"/>
                                        </Button>
                                    </Tooltip>
                                </TableCell>}
                            </TableRow>
                        )): null}
                    </TableBody>
                </Table>
                {/*<TablePagination
                    component="div"
                    count={total}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[20]}
                />*/}
            </TableContainer>

        </div>
    );
};

ReportWaste.propTypes = {
    className: PropTypes.string,
};

export default ReportWaste;
