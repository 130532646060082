import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Typography,
  Tooltip,
  Hidden,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent, DialogActions
} from '@material-ui/core';
import FindInPage from '@material-ui/icons/SearchSharp';
import AddStudent from '@material-ui/icons/PersonAdd';

import { SearchInput } from '../../../../../components';
import {withRouter} from "react-router-dom";
import {SEARCH_TEXT_STUDENTS} from "../../../../../services/auth";
import Link from "@material-ui/core/Link";
import api from "../../../../../services/api";
import {getErrorMessage} from "../../../../../helpers/error";
import Swal from "sweetalert2";

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  title: {
    fontWeight: 'bold'
  }
}));

const StudentToolbar = props => {
  const { className, onClickSearch, onChangeSearch, searchText, cabinet, onChangeCabinet, history, ...rest } = props;

  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const classes = useStyles();

  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  function loadAlert(icon, message) {
    Toast.fire({
      icon: icon,
      title: message
    });
  }

  async function handleMassUpdate(page){

    try {
      let mat = searchText;
      let date = selectedDate;
      const data = {
        mat, date
      }
      const response = await api.put('student/massUpdate', data);
      if(response.status === 200){
        loadAlert('success', response.data.message);
        window.location.reload();
      } else {
        loadAlert('error', response.data.message);
      }

    } catch (error) {

    }
    handleClose();
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const onClickHandleCampus = () => {
    history.push('/student-details');
  }

  function onPressEnter (event){
    if (event.key == "Enter"){
      onClickSearch ();
    }
   }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Typography variant="h5" className={classes.title}>{'Lista de Estudantes'}</Typography>
        <span className={classes.spacer} />
        <Hidden smDown>
          <Button variant="contained" color="primary" className={classes.exportButton}
            onClick={onClickHandleCampus}>
                Novo estudante
          </Button>
        </Hidden>
        <Hidden smUp>
          <Tooltip title="Novo estudante">
            <Button variant="contained" color="primary" className={classes.exportButton}
              onClick={onClickHandleCampus}>
                <AddStudent />
            </Button>
          </Tooltip>
        </Hidden>
      </div>
      <div className={classes.row}>
        <SearchInput
            className={classes.searchInput}
            placeholder="Pesquisar por nome, matrícula ou código"
            onChange={onChangeSearch}
            value={searchText}
            onKeyPress={onPressEnter}
        />
        <TextField
            fullWidth
            label="Selecione o Armário"
            margin="dense"
            name="cabinet"
            onChange={onChangeCabinet}
            select
            SelectProps={{ native: true }}
            value={cabinet || 0}
            style={{width: '200px'}}
            variant="outlined"
        >
          <option value=""></option>
          {Array.from({ length: 44 }, (_, index) => (
              <option key={index + 1} value={index + 1}>
                Armário {index + 1}
              </option>
          ))}
        </TextField>
        <Tooltip title="Pesquisar">
          <Button
              onClick={onClickSearch}>
            <FindInPage fontSize="large"/>
          </Button>
        </Tooltip>
      </div>
      {searchText !== ''  ? (
          <div style={{marginTop: '10px', marginLeft: '3px'}}>
            <Link href="#" onClick={handleClickOpen} style={{fontSize: '15px', fontWeight: 'bold'}}>
              Clique aqui para realizar uma atualização em massa.
            </Link>
          </div>
      ) : null}
      {/* Dialog para a atualização em massa */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Atualização em Massa</DialogTitle>
        <DialogContent>
          <div style={{
            padding: '10px',
            backgroundColor: '#f0f0f0',
            borderRadius: '4px',
            color: '#555',
            fontSize: '14px',
            lineHeight: '1.6',
            marginBottom: '16px',
            fontStyle: 'italic'
          }}>
            {'Com esta funcionalidade, você pode atualizar a data de validade do ' +
                'cadastro de vários estudantes simultaneamente. Para isso, basta ' +
                'informar uma data futura válida e as iniciais da matrícula que deseja atualizar.'}
          </div>
          <TextField
              label="Matrículas que iniciam com"
              margin="dense"
              type="input"
              disabled={true}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={searchText}
          />
          <TextField
              margin="dense"
              label="Selecione a nova data"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={selectedDate}
              onChange={handleDateChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleMassUpdate} color="primary">
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

StudentToolbar.propTypes = {
  className: PropTypes.string,
  onChangeSearch: PropTypes.func,
  onClickSearch: PropTypes.func,
  searchText: PropTypes.string,
  history: PropTypes.object
};

export default withRouter(StudentToolbar);
