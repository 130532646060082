import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid, Switch,
  Button, Checkbox, FormControlLabel,
  TextField, IconButton, Tooltip, FormGroup,
  InputLabel, MenuItem, FormControl, Select
} from '@material-ui/core';
import api from "../../../../services/api";
import Swal from "sweetalert2";
import validate from "validate.js";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getErrorMessage } from '../../../../helpers/error';
import {getCurrentDate} from "../../../../utils/dateUtils";

const schema = {
    total_food_waste: {
        presence: { allowEmpty: false, message: 'O rejeito total é obrigatório.' },
        numericality: {
            onlyInteger: true,
            greaterThan: 0,
            message: 'O rejeito total deve ser um número inteiro maior que zero.',
        }
    },
    menu_id: {
        presence: { allowEmpty: false, message: 'O menu é obrigatório.' },
    },
    waste_date: {
        presence: { allowEmpty: false, message: 'A data é obrigatória.' },

    },


};

  const useStyles = makeStyles((theme) => ({
    root: {},
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

const FoodWasteDetails = props => {
    const { className, history, ...rest } = props;
    const { idFoodWaste } = props.match.params;
    const [campus, setCampus] = React.useState([]);
    const [menusByDate, setMenusByDate] = React.useState([]);
    const classes = useStyles();

    const [formState, setFormState] = useState({
        isValid: false,
        values: {
            'active': false
        },
        touched: {},
        errors: {}
    });

    //configuration alert
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    function loadAlert(icon, message) {
        Toast.fire({
            icon: icon,
            title: message
        });
    }

    async function findFoodWaste(id) {
        try {
            const response = await api.get('food-waste/show/' + id);
            if (response.status === 202) {
                if (response.data.message) {
                    loadAlert('error', response.data.message);
                }
            } else {
                setFormState(formState => ({
                    values: {
                        'total_food_waste': response.data.total_food_waste,
                        'menu_id': response.data.menu_id,
                        'waste_date': response.data.waste_date
                    },
                    touched: {
                        ...formState.touched,
                    }
                }));
                loadMenusByDate(response.data.waste_date)
            }
        } catch (error) {
            loadAlert('error', getErrorMessage(error));
        }
    }

    useEffect(() => {
        if (idFoodWaste) {
            findFoodWaste(idFoodWaste);

        } else {
            const dateNow = getCurrentDate()
            setFormState(formState => ({
                values: {
                    'total_food_waste': 0,
                    'menu_id': 0,
                    'waste_date': dateNow
                },
                touched: {
                    ...formState.touched,
                }
            }));
            loadMenusByDate(dateNow);
        }

    }, []);

    async function loadMenusByDate(date){
        try {
            let url = 'all/menus-by-date?date='+date;
            const response = await api.get(url);
            setMenusByDate(response.data);
        } catch (error) {
            loadAlert('error', getErrorMessage (error));
        }
    }

    async function saveFoodWasteDetails() {
        try {
            const { total_food_waste, menu_id, waste_date } = formState.values;

            const data = {
                total_food_waste,
                menu_id,
                waste_date
            }
            let response = {};
            let acao = "";
            if (!idFoodWaste) {
                response = await api.post('food-waste', data);
                acao = "cadastrado";
            } else {
                response = await api.put('food-waste/' + idFoodWaste, data);
                acao = "atualizado";
            }
            if (response.status === 200) {
                loadAlert('success', 'Desperdício ' + acao + '.');
                history.push('/food-waste-report');
            } else {
                if (response.data.error) {
                    loadAlert('error', response.data.error);
                } else if (response.data.errors[0].menu_id) {
                    loadAlert('error', response.data.errors[0].menu_id);
                } else if (response.data.errors[0].total_food_waste) {
                    loadAlert('error', response.data.errors.total_food_waste);
                } else if (response.data.errors[0].waste_date) {
                    loadAlert('error', response.data.errors.waste_date);
                }
            }

        } catch (error) {
            loadAlert('error', getErrorMessage(error));
        }
    }

    useEffect(() => {
        const errors = validate(formState.values, schema);
        setFormState(formState => ({
            ...formState,
            isValid: !errors,
            errors: errors || {}
        }));
    }, [formState.values]);

    const handleBlurDate = event => {
        const { name, value } = event.target;
        if(name == 'waste_date'){
            loadMenusByDate(value);
        }
    }


    const handleChange = event => {
        const { name, value, type, checked } = event.target;

        setFormState({
            ...formState,
            values: {
                ...formState.values,
                [name]: type === 'checkbox' ? checked : value
            },
            touched: {
                ...formState.touched,
                [name]: true
            }
        });
    };

    const hasError = field =>
        formState.touched[field] && formState.errors[field] ? true : false;

    const handleBack = () => {
        history.goBack();
    };

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}>
            <form autoComplete="off">
                <div className={classes.contentHeader}>
                    <IconButton onClick={handleBack}>
                        <ArrowBackIcon />
                    </IconButton>
                </div>
                <CardHeader
                    subheader=""
                    title="Desperdício de comida" />
                <Divider />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                error={hasError('total_food_waste')}
                                helperText={
                                    hasError('total_food_waste') ? formState.errors.total_food_waste[0] : null
                                }
                                label="Rejeito Total (kg)"
                                margin="dense"
                                name="total_food_waste"
                                onChange={handleChange}
                                value={formState.values.total_food_waste || ''}
                                variant="outlined"
                                type="number"
                                inputProps={{
                                    step: "1", // Permite apenas números inteiros
                                    min: "0"   // Define o valor mínimo como 0
                                }}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                error={hasError('waste_date')}
                                helperText={
                                    hasError('waste_date') ? formState.errors.waste_date[0] : null
                                }
                                label="Data"
                                margin="dense"
                                name="waste_date"
                                onChange={handleChange}
                                onBlur={handleBlurDate}
                                value={formState.values.waste_date || ''}
                                variant="outlined"
                                type="date"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                error={hasError('menu_id')}
                                helperText={
                                    hasError('menu_id') ? formState.errors.menu_id[0] : null
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label="Menu"
                                margin="dense"
                                name="menu_id"
                                onChange={handleChange}
                                select
                                SelectProps={{ native: true }}
                                value={formState.values.menu_id || ''}
                                variant="outlined">
                                <option value="" disabled>Selecione um menu</option>
                                {menusByDate.map(result => (
                                    <option
                                        key={result.id}
                                        value={result.id}>
                                        {result.meal.description} - {result.description}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                    <Tooltip title="Clique aqui para salvar os dados" aria-label="add">
                        <Button
                            color="primary"
                            variant="outlined"
                            disabled={!formState.isValid}
                            onClick={saveFoodWasteDetails}>
                            Salvar
                        </Button>
                    </Tooltip>
                </CardActions>
            </form>
        </Card>
    );
};

FoodWasteDetails.propTypes = {
    className: PropTypes.string,
};

export default FoodWasteDetails;
