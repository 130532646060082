import {
    Card, Button,
    CardHeader, Divider,
    Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, useMediaQuery, Typography, FormControl, RadioGroup, FormControlLabel, Radio, FormGroup, Checkbox
} from '@material-ui/core';
import { Close, Done } from "@material-ui/icons";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Swal from "sweetalert2";
import Padding from '../Padding';
import api from '../../services/api';
import { getErrorMessage } from '../../helpers/error';
import { FormLabel } from 'react-bootstrap';

const FormsDialog = props => {
    const mediumScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const { className, history, ...rest } = props;
    const [formToShow, setFormToShow] = useState({
        form: {
            perguntas: []
        },
        showForm: false
    });
    const [formToResponse, setFormToResponse] = useState({});
    //configuration alert
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    function loadAlert(icon, message) {
        Toast.fire({
            icon: icon,
            title: message
        });
    }

    async function loadForm() {
        try {
            const url = 'student/forms';
            const response = await api.get(url);
            if (response.status === 200) {
                setFormToShow(response.data);
                setFormToResponse({...formToResponse, form_id: formToShow.form.id })
            }
        } catch (error) {

            loadAlert('error', getErrorMessage(error));
        }
    }

    function closeFormAlert() {
        props.onChange(false);
        let dateToday = new Date();
        let dateTodayString = dateToday.getFullYear() + "-" + (dateToday.getMonth() + 1) + "-" + dateToday.getDate();
        // localStorage.setItem('pular_form', dateTodayString);
        localStorage.setItem('pular_form', true);
    }

    async function saveRespostaForm(e) {
        e.preventDefault();

        try {
            let response = {};
            response = await api.post('student/forms/response', { data: formToResponse });

            if (response.status === 200) {
                props.onChange(false);
                loadAlert('success', 'Respostas enviadas');
            } else {
                loadAlert('error', "response.data.message");
            }

        } catch (error) {

            loadAlert('error', getErrorMessage(error));
        }
    }

    return (
        <Padding sx={{ px: [2, null, 0] }}>
            <form onSubmit={saveRespostaForm} autoComplete="off">
                <div style={{width: "480px", maxWidth: "100%"}}>
                <iframe height="780" style={{width: "100%"}} src="https://docs.google.com/forms/d/e/1FAIpQLScDPtTZkj5UYyHnqKbmZXamQ0hqqbvBsH42ueLmSP6tBD3W5w/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">Carregando…</iframe>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            columnSpacing={2}>
                            {false ? (
                                <Button disabled style={{ marginRight: "24px" }}>
                                    Fechar formulário
                                </Button>
                            ) : (
                                <Button
                                    onClick={closeFormAlert}
                                    color="primary" style={{ marginRight: "24px" }}>
                                    Fechar formulário
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </form>
        </Padding>
    );
}

FormsDialog.propTypes = {
    className: PropTypes.string,
};

export default FormsDialog;