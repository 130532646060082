import React, { Component } from "react";
import { withRouter } from "react-router-dom";

//import  "font-awesome/css/font-awesome.css";

class Admin extends Component{
    
    render(){
        return(
        <div className={'container'} height={100}> 

        </div>
        );
    }
}

export default withRouter(Admin);