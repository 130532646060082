import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import HeaderNutri from "../../../components/HeaderNutri";
import HeaderTitle from "../../../components/HeaderTitle";
import FooterAdmin from "../../../components/FooterAdmin";
import HomeNutri from "../../../components/HomeNutri";

class Nutritionist extends Component{
    
    render(){
        return(
        <div className={'container'} height={100}>
    

        </div>
        );
    }
}

export default withRouter(Nutritionist);
