import { React, useState, useEffect } from 'react'
import { supabase } from '../../../services/supabaseApi'

import './FormsAssistance.css'

function FormsAssistance() {
  const [respostas, setRespostas] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function fetchRespostas() {
      setIsLoading(true)
      const { data, error } = await supabase
        .from('respostas')
        .select('*')
        .eq('form_id', 1) // ID da enquete ativa
        .eq("p1", true)
        .order('student_name', { ascending: true })

      if (error) {
        console.error("Erro ao buscar respostas:", error)
        setIsLoading(false)
        return
      }

      setRespostas(data)
      setIsLoading(false)
    }

    fetchRespostas()
  }, [])

  if (isLoading) return <div>Carregando respostas...</div>

  return (
    <div className="enquete-view">
      <h1 className="page-title">Respostas da Enquete Ativa</h1>
      {respostas.length === 0 ? (
        <p>Nenhuma resposta foi recebida ainda.</p>
      ) : (
        <>
          <header>
            <div>
              <h2>Enquete atual:</h2>
              <p>Uso do restaurante durante a greve.</p>
            </div>
            <div>
              <h2>Pergunta 01:</h2>
              <p>Você é aluno/a de República?</p>
            </div>
            <div>
              <h2>Pergunta 02:</h2>
              <p>Caso sim, vai permanecer na república neste período de greve, precisando assim das refeições no campus?</p>
            </div>
          </header>

          <div className="dashboard">
            <div className="dashboard-content">
              <h2>Estatísticas das respostas:</h2>
              <div className="statistic">
                <span>Alunos de república:</span>
                <strong>{respostas.filter((resposta) => resposta.p1).length}</strong>
              </div>
              <div className="statistic">
                <span>Alunos que precisarão de refeições:</span>
                <strong>{respostas.filter((resposta) => resposta.p2).length}</strong>
              </div>
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <th>Nome do Aluno</th>
                <th>Resposta 1</th>
                <th>Resposta 2</th>
                {/* Adicione mais colunas conforme necessário */}
              </tr>
            </thead>
            <tbody>
              {respostas.map((resposta) => (
                <tr key={resposta.id}>
                  <td>{resposta.student_name}</td>
                  <td>{resposta.p1 ? 'Sim' : 'Não'}</td>
                  <td>{resposta.p2 ? 'Sim' : 'Não'}</td>
                  {/* Adicione mais células conforme necessário */}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  )
}

export default FormsAssistance