import { React, useState, useEffect } from 'react'
import { supabase } from '../../../services/supabaseApi'
import './FormStudents.css'
import { SaveRounded } from '@material-ui/icons'

function FormStudents({ student_id }) {
  const [resposta1, setResposta1] = useState(false)
  const [resposta2, setResposta2] = useState(false)
  // Adicionado para gerenciar o estado de carregamento
  const [isLoading, setIsLoading] = useState(true)
  const [jaRespondeu, setJaRespondeu] = useState(false)

  const student_name = localStorage.getItem('@rucedro-name-user')
  const student_first_name = student_name.split(' ')[0].toLowerCase()

  useEffect(() => {
    async function getStudent() {
      setIsLoading(true)
      const { data: resposta_db, error } = await supabase
        .from('respostas')
        .select('*')
        .eq('student_id', student_id)
      if (error) {
        console.error(error)
      } else {
        setJaRespondeu(resposta_db.length > 0)
      }
      setIsLoading(false)
    }
    getStudent()
  }, [student_id])

  async function handleSubmit(e) {
    e.preventDefault()
    const { data, error } = await supabase
      .from('respostas')
      .insert([
        {
          form_id: 1,
          student_id,
          student_name,
          p1: resposta1,
          p2: resposta2,
        },
      ])
    if (error) {
      console.error(error)
    } else {
      setJaRespondeu(true) // Atualiza o estado para refletir que o usuário já respondeu
    }
  }

  // Mostra uma mensagem de carregamento enquanto verifica se o usuário já respondeu
  if (isLoading) return <div>Carregando...</div>

  // Não mostrar nada se o usuário já respondeu
  if (jaRespondeu) return <></>

  // Renderização do formulário se o usuário não tiver respondido
  return (
    <>
      <main className="forms-cae">
        <h1>A CAE pergunta:</h1>
        <h6>Responda as perguntas a seguir com SIM ou NÃO.</h6>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              01 - <strong className="student-name">{student_first_name}</strong>, você é aluno/a de República?
            </label>
            <label className="switch">
              <input
                type="checkbox"
                checked={resposta1}
                onChange={() => {
                  setResposta1(!resposta1)
                  setResposta2(false)
                }}
              />
              <span className="slider"></span>
            </label>
          </div>

          {resposta1 && (
            <div>
              <label>02 - Você vai permanecer na república neste período de greve, precisando assim das refeições no campus?</label>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={resposta2}
                  onChange={() => setResposta2(!resposta2)}
                />
                <span className="slider"></span>
              </label>
            </div>
          )}
          <button type="submit">
            <SaveRounded />
            <span>Enviar respostas</span>
          </button>
        </form>
      </main>
      <div className="forms-cae-overlay"></div>
    </>
  )
}

export default FormStudents