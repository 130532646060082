import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination, Hidden, IconButton
} from '@material-ui/core';
import api from '../../../../services/api';
import {DialogQuestione} from "../../../../components";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import StudentToolbar from "./components/StudentToolbar";
import StudentRow from "./components/StudentRow";
import { getErrorMessage } from '../../../../helpers/error';
import {SEARCH_TEXT_STUDENTS, setSearchTextStudents} from "../../../../services/auth";
import Alert from "@material-ui/lab/Alert";
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    padding: 0,
    marginTop: theme.spacing(2)
  },
  inner: {
    minWidth: "100%"
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  headTable: {
    fontWeight: "bold"
  },
  actions: {
    justifyContent: 'flex-end'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const TableStudent = props => {
  const { className, history } = props;

  const [students, setStudents] = useState([]);

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState(() => {
    return new URLSearchParams(history.location.search).get('query') ?? "";
  });
  const [open, setOpen] = React.useState(false);
  const [idStudentDelete, setIdStudentDelete] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [unprocessedJustifications, setUnprocessedJustifications] = useState([]);
  const [openUnprocessdJustification, setOpenUnprocessdJustification] = React.useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [cabinetSearch, setCabinetSearch] = useState(0);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setOpenConfirmDialog(true);
  };

  const handleCancel = () => {
    setOpenConfirmDialog(false);
    setSelectedItem(null);
  };


  const handleClickOpenUnprocessdJustification = () => {
    setOpenUnprocessdJustification(true);
  };

  const handleCloseUnprocessdJustification = () => {
    setOpenUnprocessdJustification(false);
  };

  //configuration alert
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  function loadAlert(icon, message) {
    Toast.fire({
      icon: icon,
      title: message
    });
  }

  async function loadStudent(page){
    setLoading(true);
    let str = localStorage.getItem(SEARCH_TEXT_STUDENTS);

    try {
      let url = 'student?page='+page;
      if(str != '' && str != null){
        url += '&name='+str;
      }
      if(Number(cabinetSearch)>0){
        url += '&cabinet='+cabinetSearch;
      }
      const response = await api.get(url);
      setTotal(response.data.total);
      setStudents(response.data.data);
    } catch (error) {

      loadAlert('error', getErrorMessage (error));
    }
    setLoading(false);
  }

  async function loadUnprocessedJustifications(){
    try {
      let url = 'scheduling/unprocessed-justifications';

      const response = await api.get(url);
      if(response.data) {
        setUnprocessedJustifications(response.data)
      } else {
        setUnprocessedJustifications([]);
      }
    } catch (error) {

      loadAlert('error', getErrorMessage (error));
    }
  }

  async function handleProcessedJustifications(id){
    try {

      let url = 'scheduling/processed-justifications/'+selectedItem.id;

      const response = await api.put(url);
      if(response.status == 200){
        loadAlert('success', 'Justificativa processada.');
      }


      setOpenConfirmDialog(false);
      setOpenUnprocessdJustification(false);
      window.location.reload();


    } catch (error) {

      loadAlert('error', getErrorMessage (error));
    }
  };

  useEffect(() => {
    setSearchText(searchText => new URLSearchParams(history.location.search).get('query') ?? searchText);

    loadStudent(1);
  }, [history.location.search]);

  useEffect(() => {
    setSearchText(localStorage.getItem(SEARCH_TEXT_STUDENTS));
    loadUnprocessedJustifications();
  }, []);

  const updateSearch = (e) => {
    setSearchText(e.target.value);
  }

  const onChangeCabinet = (e) => {
    setCabinetSearch(e.target.value)
  }

  const onClickSearch = (e) => {
    history.push(history.location.pathname + (searchText ? '?query=' + searchText : ''));

    setSearchTextStudents(searchText);
    
    setPage(0);
    loadStudent(1);
  }

  const handlePageChange = (event, page) => {
    loadStudent(page+1)
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const onClickOpenDialog = (id) => {
    setIdStudentDelete(id);
    setOpen(true);
  }

  const onClickCloseDialog = () => {
    setOpen(false);
    setIdStudentDelete(0);
  }

  const onClickVerifyHistory = (id) => {
    history.push('/student-history/'+id);
  }

  const onClickVerifyAllowMeal = (id) => {
    history.push('/student-allow-meal/'+id);
  }

  async function onDeleteObject(){
    try {
      let url = 'student/'+idStudentDelete;
      const response = await api.delete(url);
      if (response.status === 202) {
        if(response.data.message){
          loadAlert('error', response.data.message);
        }
      } else {
        loadAlert('success', 'Estudante excluído.');
        loadStudent(page+1);
      }
    } catch (error) {

      loadAlert('error', getErrorMessage (error));
    }
    setOpen(false);
  }

  const onClickEdit = (id) => {
    history.push('/student-details/'+id);
  }

  return (
    <div>
      <div className={classes.root}>
        <StudentToolbar
          onChangeSearch={updateSearch.bind(this)}
          searchText={searchText}
          cabinet={cabinetSearch}
          onChangeCabinet={onChangeCabinet.bind(this)}
          onClickSearch={onClickSearch}
        />
        <div className={classes.content}>
          <Card className={clsx(classes.root, className)}>
            <CardActions className={classes.actions}>
              <TablePagination
                  component="div"
                  count={total}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[10]}
              />
            </CardActions>
            {unprocessedJustifications && unprocessedJustifications.length > 0 &&
                <Alert severity="error" style={{cursor: 'pointer'}}npm start className={classes.newsAlert} onClick={handleClickOpenUnprocessdJustification}>
                  Há justificativas de ausência de alunos que precisam ser analisadas.
                </Alert>
            }
            <CardContent className={classes.content}>
              <div className={classes.inner}>
                {loading === true ? (
                  <LinearProgress />
                ) : students == "" ? (
                  <Table>
                    <TableBody>
                      <TableRow key={0}>
                        <TableCell
                          align="center"
                          colSpan={9}
                          className={classes.headTable}
                        >
                          {" "}
                          Nenhum dado foi encontrado para a pesquisa realizada!
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                ) : (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Cód.
                        </TableCell>
                        <Hidden smDown>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Matrícula
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Chave
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Nome
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Email
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Curso
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Validade
                          </TableCell>
                          <TableCell className={classes.headTable}></TableCell>
                          <TableCell className={classes.headTable}></TableCell>
                          <TableCell className={classes.headTable}></TableCell>
                        </Hidden>
                        <Hidden mdUp>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Nome
                          </TableCell>
                          <TableCell></TableCell>
                        </Hidden>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {students.map((result) => (
                          <StudentRow
                            key={result.id}
                            student={result}
                            onVerifyHistory={() =>
                              onClickVerifyHistory(result.id)
                            }
                            onVerifyPermissions={() =>
                              onClickVerifyAllowMeal(result.id)
                            }
                            onDelete={() => onClickOpenDialog(result.id)}
                            onEdit={() => onClickEdit(result.id)}
                          />
                      ))}
                    </TableBody>
                  </Table>
                )}
              </div>
            </CardContent>
            <CardActions className={classes.actions}>
              <TablePagination
                component="div"
                count={total}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10]}
              />
            </CardActions>
          </Card>
          <DialogQuestione
            handleClose={onClickCloseDialog}
            open={open}
            onClickAgree={onDeleteObject}
            onClickDisagree={onClickCloseDialog}
            mesage={"Deseja excluir o aluno selecionado?"}
            title={"Excluir turno"}
          />
        </div>
      </div>
      <Dialog fullScreen open={openUnprocessdJustification}
              onClose={handleCloseUnprocessdJustification}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleCloseUnprocessdJustification} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Justificativas não processadas
            </Typography>

          </Toolbar>
        </AppBar>
        <List>
          {unprocessedJustifications && unprocessedJustifications.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem button onClick={() => handleItemClick(item)}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body1" display="block" gutterBottom>
                      Matrícula: {item.student && item.student.mat}
                    </Typography>
                    <Typography variant="body1" display="block" style={{ fontWeight: 'bold' }} gutterBottom>
                      Estudante: {item.student && item.student.name}
                    </Typography>
                    <Typography variant="body1" display="block" gutterBottom>
                      Curso: {item.student && item.student.course.description}
                    </Typography>
                    <Typography variant="body1" display="block" style={{ fontWeight: 'bold' }} gutterBottom>
                     Refeição:  {item.meal && item.meal.description +" do dia "+
                      item.date
                          .toString()
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                    </Typography>
                    <Typography variant="body1" display="block" style={{ fontWeight: 'bold' }} gutterBottom>
                      Justificativa: {item.studentJustification}
                    </Typography>
                  </div>

                </ListItem>
                {/* Adiciona um Divider entre os itens, exceto no final */}
                {index < unprocessedJustifications.length - 1 && <Divider />}
              </React.Fragment>
          ))}
        </List>
      </Dialog>
      {/* Dialog de confirmação */}
      <Dialog
          open={openConfirmDialog}
          onClose={handleCancel}
          aria-labelledby="confirm-dialog-title">
        <DialogTitle id="alert-dialog-title">{"Justificativa não processada"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body1">
              Deseja aceitar a justificativa do estudante "{selectedItem?.student?.name}" para a refeição "{selectedItem?.meal?.description}"?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleProcessedJustifications} color="primary" >
            Sim
          </Button>
          <Button onClick={handleCancel} color="secondary" >
            Não
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

TableStudent.propTypes = {
  history: PropTypes.object
};

export default withRouter(TableStudent);

